import {
  BandStatusReportRawData,
  BillingCareMemberRawData,
  EngagementReportRawData,
} from 'models/reports';

export const engagementReportMock: EngagementReportRawData[] = [
  {
    id: 'fffcfcc3-5175-4822-92eb-0e0f92b14b5f',
    reportId: '04c6aa65-c957-487b-9a2d-72b1099e579c',
    uid: '490888f4-1fa4-4716-8662-c112b2eda95e',
    mrn: null,
    givenName: 'Tester 41',
    middleName: null,
    familyName: 'Cool 41',
    email: 'cool41@tester.test',
    tenantId: '798d0d1f-2b6e-4d84-a626-1ac97b36d3f6',
    day: 29,
    month: 8,
    year: 2023,
    numberVisits: 30,
    numberVisitsAverage: 30 / 7,
    numberDevicesBand: 2,
    bandWearingSeconds: 86000,
    bandRemovedSeconds: 400,
    totalDaysBandSynced: 1,
    numberDevicesThirdParty: 2,
    totalTimesThirdPartySynced: 5,
    reportGeneratedAt: '2023-08-29T14:20:21.816+00:00',
    bandChargeSeconds: 0,
    bpReadings: 0,
    glucometerReadings: 0,
    weightReadings: 0,
    externalEmployeeId: null,
    externalMrn: null,
  },
];

export const bandStatusReportMock: BandStatusReportRawData[] = [
  {
    id: 'fffcfcc3-5175-4822-92eb-0e0f92b14b5f',
    reportId: '04c6aa65-c957-487b-9a2d-72b1099e579c',
    uid: '490888f4-1fa4-4716-8662-c112b2eda95e',
    givenName: 'Tester 41',
    middleName: null,
    familyName: 'Cool 41',
    email: 'cool41@tester.test',
    tenantId: '798d0d1f-2b6e-4d84-a626-1ac97b36d3f6',
    day: 29,
    month: 8,
    year: 2023,
    numberBandsActivated: 1,
    activeBandId: 'SOMEBANDID',
    bandReplacementActivations: 1,
    bandDeactivations: 1,
    bandActiveSystem: 1,
    bandVersion: '2',
    bandFirmwareVersion: '1.6.3-6dbb3ec3-relea',
    bandBatteryLevel: 22,
    reportGeneratedAt: '2023-08-29T14:20:21.816+00:00',
  },
];

export const careTeamTimeTrackingMock: BillingCareMemberRawData[] = [
  {
    id: 'fffcfcc3-5175-4822-92eb-0e0f92b14b5f',
    reportId: '04c6aa65-c957-487b-9a2d-72b1099e579c',
    tenantId: '798d0d1f-2b6e-4d84-a626-1ac97b36d3f6',
    day: 29,
    month: 8,
    year: 2023,
    careMemberId: '238753ee-0017-4cfb-bdd1-91af3221b029',
    careMemberRole: ['nurse'],
    careMemberAssignedRole: 'Nurse',
    careMemberTotalSecondsSpent: 9,
    careTeamTotalSecondsSpent: 9,
    careMemberFamilyName: '',
    careMemberGivenName: '',
    careMemberMiddleName: '',
    isInTheTenant: true,
    isOnHold: false,
    numDaysIsInTheTenant: 0,
    numDaysIsInTheTenantSinceOnboarded: 0,
    numDaysIsOnHold: 0,
    numDaysIsOnHoldSinceOnboarded: 0,
    mrn: '490888f4-1fa4-4716-8662-c112b2eda95e',
    qhcp: false,
    memberGivenName: 'Tester 41',
    memberMiddleName: null,
    memberFamilyName: 'Cool 41',
    memberEmail: 'cool41@tester.test',
    memberOnboardedTimestamp: '2023-05-16T20:14:54.284+00:00',
    memberCreatedAt: '2023-05-16T20:14:54.284+00:00',
    memberSecondsEnrolled: 9072628,
    reportGeneratedAt: '2023-08-29T14:20:21.816+00:00',
    externalEmployeeId: null,
    externalMrn: null,
  },
];
