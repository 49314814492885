import { isArray, keyBy, map, round, sortBy } from 'lodash';

import {
  BandStatusReportRawData,
  BillingCareMemberRawData,
  BiometricsRawReport,
  BiometricsReport,
  EngagementCareMemberRawData,
  EngagementReportRawData,
  PeriodItem,
  ReportResponse,
} from 'models/reports';
import { userReportsApi } from '.';
import { getTimeInUTC, queryParamsReportsByPeriod } from 'utils/reports';
import { reportFromBandData } from 'utils/reportsBandStatus';
import { reportFromBillingCareMemberData } from 'utils/reportsBillingCareMember';
import type { RootState } from 'store/rootReducer';
import { User } from 'models/user';
import { reportFromEngagementData } from 'utils/reportsEngagement';
import { format, parseISO } from 'date-fns';
import { reportFromComplianceData } from 'utils/reportsCompliance';
import { Program, ProgramCompletionRawData } from 'models/programCompletionReport';
import { reportsFromActionSpec } from 'utils/reportsActionSpec';
import fetchFactuaryCounters from './helpers/fetchFactuaryCounters';
import fetchUsers from './helpers/fetchUsers';
import { ActionSpecPDFData, ActionSpecReport } from 'models/actionSpecReport';
import { reportFromBiometricsData } from 'utils/reportsBiometrics';
import { reportFromProgramAggregations } from 'utils/reportsProgramCompletion';
import { getReportMemberStatus } from 'utils/memberStatus';
import fetchOnboardedDates from './helpers/fetchOnboardedDates';

interface ReportQueryParams {
  period: PeriodItem;
  clinicianId?: string;
}

export const adminReportsApi = userReportsApi.injectEndpoints({
  endpoints(builder) {
    return {
      // Care Team Time Tracking Report
      fetchCareTeamTimeTrackingReport: builder.query<
        {
          groupedData: BillingCareMemberRawData[];
          ungroupedData: BillingCareMemberRawData[];
          engagementSummaryData: EngagementCareMemberRawData;
          careTeamSummaryData: Partial<BillingCareMemberRawData>[];
        },
        ReportQueryParams
      >({
        async queryFn(queryParams, queryApi, extraOptions, baseQuery) {
          const startTime = getTimeInUTC(queryParams?.period?.value?.start);
          const endTime = getTimeInUTC(queryParams?.period?.value?.end) + 1;

          const careTeamTrackingResponse = await baseQuery({
            url: `/ticks-store/api/v1/reports/time-tracking?timestamp_from=${startTime}&timestamp_to=${endTime}`,
            method: 'GET',
          });

          if (careTeamTrackingResponse.error) {
            return { error: careTeamTrackingResponse.error };
          }

          const careTeamTrackingData = (careTeamTrackingResponse?.data ?? []) as any;

          const reports = reportFromBillingCareMemberData(careTeamTrackingData, queryParams.period);

          return {
            data: {
              groupedData: reports.billingByPatient,
              ungroupedData: reports.billingData,
              engagementSummaryData: reports.engagementSummaryData,
              careTeamSummaryData: reports.careTeamSummaryData,
            },
          };
        },
        providesTags: ['Reports'],
      }),

      // Population Engagement Report
      fetchEngagementReport: builder.query<EngagementReportRawData[], ReportQueryParams>({
        async queryFn(queryParams, queryApi, extraOptions, baseQuery) {
          const startTime = getTimeInUTC(queryParams?.period?.value?.start);
          const endTime = getTimeInUTC(queryParams?.period?.value?.end) + 1;

          const engagementResponse = await baseQuery({
            url: `/ticks-store/api/v1/reports/engagement?timestamp_from=${startTime}&timestamp_to=${endTime}`,
            method: 'GET',
          });

          if (engagementResponse.error) {
            return { error: engagementResponse.error };
          }

          const engagementData = (engagementResponse?.data ?? []) as EngagementReportRawData[];

          if (!engagementData?.length) {
            return {
              data: [],
            };
          }

          // formatting engagement reports
          const reportsData = reportFromEngagementData({
            rawData: engagementData,
            period: queryParams.period,
          });

          return {
            data: reportsData,
          };
        },
        providesTags: ['Reports'],
      }),
      fetchAdditionalData: builder.query<
        {
          bandActivationsData: BandStatusReportRawData;
          bandData: BandStatusReportRawData[];
          accountsChartData: any;
        },
        ReportQueryParams
      >({
        async queryFn(queryParams, queryApi, __, baseQuery) {
          const isAdmin = (queryApi.getState() as RootState).auth.tenant.roles.includes(
            'tenant_admin'
          );
          const params = queryParamsReportsByPeriod(queryParams.period);
          const reportFrom = format(queryParams?.period.value.start, 'yyyy-MM-dd');
          const reportTo = format(queryParams?.period.value.end, 'yyyy-MM-dd');

          if (!isAdmin) {
            const patientsResponse = await baseQuery({
              url: `/registry/api/v1/users/${queryParams?.clinicianId}/patients`,
              method: 'GET',
            });

            if (patientsResponse.error) {
              return { error: patientsResponse.error };
            }

            const patientIds = (patientsResponse.data as User[])
              .map((patient) => patient.id)
              .join(',');
            params.append('patientIds', patientIds);
          }

          const accountsResponse = await baseQuery({
            url: `/datalake-service/v1/reports/engagement-care-member/daily?reportFrom=${reportFrom}&reportTo=${reportTo}`,
            method: 'GET',
          });
          const accounts = accountsResponse?.data as ReportResponse;
          const accountsData =
            accounts?._embedded?.engagementCareMemberDailyReportList ||
            accounts?._embedded?.engagementCareMemberMonthlyReportList ||
            accounts?._embedded?.engagementCareMemberWeeklyReportList ||
            [];

          const accountsChartData = sortBy(
            accountsData.map((day: any) => ({
              date: format(new Date(parseISO(day?.reportDate)), 'EEE, LLL dd'),
              value: day?.totalUsersSystem || null,
              timestamp: new Date(parseISO(day?.reportDate)).getTime(),
            })),
            'timestamp'
          );

          let bandData: BandStatusReportRawData[] = [];
          let isBandNext = true;
          let pageBandNumber = 0;

          while (isBandNext) {
            if (params.has('pageNumber')) {
              params.delete('pageNumber');
            }
            params.append('pageNumber', `${pageBandNumber}`);
            const bandResponse = await baseQuery({
              url: `/datalake-service/v1/reports/engagement-band/${
                queryParams.period.period
              }?${params.toString()}`,
              method: 'GET',
            });

            if (bandResponse.error) {
              return { error: bandResponse.error };
            }

            const responseData = bandResponse.data as ReportResponse;
            const bandList =
              ((responseData?._embedded?.engagementBandDailyReportList ||
                responseData?._embedded?.engagementBandMonthlyReportList ||
                responseData?._embedded
                  ?.engagementBandWeeklyReportList) as BandStatusReportRawData[]) || [];

            isBandNext = !!responseData?._links?.next;
            bandData = [...bandData, ...bandList];
            pageBandNumber++;
          }

          const bandActivationsData = map(
            bandData?.filter(
              (band) => band?.numberBandsActivated && band?.numberBandsActivated > 0
            ),
            (bandObj) => ({
              ...bandObj,
              bandAge: Math.ceil((bandObj.bandAgeSeconds || 0) / 86400),
            })
          ) as any;

          return {
            data: { bandActivationsData, bandData, accountsChartData },
          };
        },
        providesTags: ['Reports'],
      }),

      // Population Compliance Report
      fetchComplianceReport: builder.query<EngagementReportRawData[], ReportQueryParams>({
        async queryFn(queryParams, queryApi, extraOptions, baseQuery) {
          const startTime = getTimeInUTC(queryParams?.period?.value?.start);
          const endTime = getTimeInUTC(queryParams?.period?.value?.end) + 1;
          const complianceResponse = await baseQuery({
            url: `/ticks-store/api/v1/reports/compliance?timestamp_from=${startTime}&timestamp_to=${endTime}`,
            method: 'GET',
          });

          if (complianceResponse.error) {
            return { error: complianceResponse.error };
          }

          const complianceData = (complianceResponse?.data ?? []) as EngagementReportRawData[];

          // formatting complaince report
          const complianceReportData = reportFromComplianceData({
            rawData: complianceData,
            period: queryParams.period,
          });

          return { data: complianceReportData };
        },
        providesTags: ['Reports'],
      }),

      // Population Biometrics Report
      fetchBiometricsReport: builder.query<BiometricsReport[], ReportQueryParams>({
        async queryFn(queryParams, queryApi, extraOptions, baseQuery) {
          const startTime = getTimeInUTC(queryParams?.period?.value?.start);
          const endTime = getTimeInUTC(queryParams?.period?.value?.end) + 1;
          const biometricsResponse = await baseQuery({
            url: `/ticks-store/api/v1/reports/biometric?timestamp_from=${startTime}&timestamp_to=${endTime}`,
            method: 'GET',
          });

          if (biometricsResponse.error) {
            return { error: biometricsResponse.error };
          }

          const biometricsData = (biometricsResponse?.data ?? []) as BiometricsRawReport[];

          // formatting biometrics report
          const biometricsReportData = reportFromBiometricsData({
            rawData: biometricsData,
            period: queryParams.period,
          });

          return { data: biometricsReportData };
        },
        providesTags: ['Reports'],
      }),

      fetchAdminBandStatusReport: builder.query<BandStatusReportRawData[], ReportQueryParams>({
        async queryFn(queryParams, _, __, baseQuery) {
          const params = queryParamsReportsByPeriod(queryParams.period);
          let data: BandStatusReportRawData[] = [];
          let isNext = true;
          let pageNumber = 0;

          while (isNext) {
            params.append('pageNumber', `${pageNumber}`);
            const reportsResponse = await baseQuery({
              url: `/datalake-service/v1/reports/engagement-band/${
                queryParams.period.period
              }?${params.toString()}`,
              method: 'GET',
            });

            if (reportsResponse.error) {
              return { error: reportsResponse.error };
            }

            const responseData = reportsResponse.data as ReportResponse;
            const reportList =
              ((responseData?._embedded?.engagementBandDailyReportList ||
                responseData?._embedded?.engagementBandMonthlyReportList ||
                responseData?._embedded
                  ?.engagementBandWeeklyReportList) as BandStatusReportRawData[]) || [];

            isNext = !!responseData?._links?.next;
            data = [...data, ...reportList];
            pageNumber++;
          }

          const reportsData: BandStatusReportRawData[] =
            isArray(data) && data.length > 0 ? reportFromBandData(data) : [];

          return { data: reportsData };
        },
        providesTags: ['AdminReports'],
      }),

      fetchProgramsCompletionReport: builder.query<ProgramCompletionRawData[], ReportQueryParams>({
        async queryFn(queryParams, queryApi, extraOptions, baseQuery) {
          // fetch registry to get list of patients
          const patients = await fetchUsers({
            queryParams: { userStatus: 'ALL' },
            baseQuery,
          });
          const patientKeys = keyBy(patients, 'id');

          // fetch users onboarded date
          const onboardedDates = await fetchOnboardedDates({
            extraOptions,
            queryApi,
          });

          const from_ts = round(queryParams.period.value.start.getTime() / 1000);
          const to_ts = round(queryParams.period.value.end.getTime() / 1000);

          const programsResponse = await baseQuery({
            url: `/factuary/api/v2/users/programs/aggregations?from_ts=${from_ts}&to_ts=${to_ts}`,
            method: 'GET',
          });

          if (programsResponse.error) {
            return { error: programsResponse.error };
          }

          const programsData = programsResponse.data as {
            data: Record<string, Program[]>;
          };

          const data: ProgramCompletionRawData[] = Object.keys(programsData.data).flatMap(
            (userId) => {
              const patient = patientKeys[userId];
              const onboardedAt = onboardedDates[userId];
              const programs = programsData.data[userId];

              return programs.map((program) => {
                const totals = reportFromProgramAggregations(program.program_aggregations);
                return {
                  userId: patient.id,
                  firstName: patient.personalInfo.firstName,
                  lastName: patient.personalInfo.lastName,
                  mrn: patient.externalIds?.MRN,
                  employeeId: patient.externalIds?.EMPLOYEE_ID,
                  userStatus: getReportMemberStatus(patient, !!onboardedAt),
                  createdDate: patient.createdAt
                    ? format(new Date(patient.createdAt), 'MM/dd/yyyy')
                    : '',
                  createdAt: patient.createdAt,
                  onboardedAt,
                  onboardedDate: onboardedAt ? format(new Date(onboardedAt), 'MM/dd/yyyy') : '',
                  programId: program.program_id,
                  programName: program.program_name,
                  ...totals,
                };
              });
            }
          );

          return { data };
        },
        providesTags: ['Reports'],
      }),

      fetchActionSpecReport: builder.query<
        { data: ActionSpecReport[]; pdfData: ActionSpecPDFData },
        ReportQueryParams
      >({
        async queryFn(queryParams, queryApi, extraOptions, baseQuery) {
          const isObserver = (queryApi.getState() as RootState).auth.tenant.roles.includes(
            'observer'
          );

          // fetch registry to get list of patients
          const patients = await fetchUsers({
            queryParams: { userStatus: 'ALL' },
            baseQuery,
          });

          if (!patients.length) {
            return {
              data: {
                data: [],
                pdfData: {
                  patientsAmount: 0,
                  openedCount: 0,
                  closedCount: 0,
                  completedCount: 0,
                  chartData: [],
                },
              },
            };
          }

          // fetch factuary counters data (content / actions)
          const { countersByUser, pdfData } = await fetchFactuaryCounters({
            queryParams: {
              ...queryParams,
              clinicianId: !isObserver ? queryParams?.clinicianId : undefined,
            },
            baseQuery,
          });

          const data = reportsFromActionSpec({
            patients,
            factuaryCounters: countersByUser,
            clinicianId: !isObserver ? queryParams?.clinicianId : undefined,
          });

          return { data: { data, pdfData } };
        },
        providesTags: ['Reports'],
      }),
    };
  },
});

export const {
  useLazyFetchCareTeamTimeTrackingReportQuery,
  useLazyFetchEngagementReportQuery,
  useLazyFetchAdditionalDataQuery,
  useLazyFetchComplianceReportQuery,
  useLazyFetchBiometricsReportQuery,
  useLazyFetchAdminBandStatusReportQuery,
  useLazyFetchProgramsCompletionReportQuery,
  useLazyFetchActionSpecReportQuery,
} = adminReportsApi;
