import { ReportTabItem } from 'models/reports';

export const EXTERNAL_IDS: Record<string, string> = {
  MRN: 'externalMrn',
  EMPLOYEE_ID: 'externalEmployeeId',
};

export const CLINICIAN_TAB_REPORTS: Array<ReportTabItem> = [
  {
    key: 'billing',
    name: 'Care Member Time Tracking',
  },
  {
    key: 'population-compliance',
    name: 'Population Compliance',
  },
  {
    key: 'population-engagement',
    name: 'Population Engagement',
  },
  {
    key: 'population-biometrics',
    name: 'Population Biometrics',
  },
  // TODO: show when BE is fully ready
  // {
  //   key: 'program-completion',
  //   name: 'Program Completion',
  // },
  {
    key: 'action-spec',
    name: 'Action Spec',
  },
];

export const ADMIN_TAB_REPORTS: Array<ReportTabItem> = [
  {
    key: 'care-team-time-tracking',
    name: 'Care Team Time Tracking',
  },
  {
    key: 'population-compliance',
    name: 'Population Compliance',
  },
  {
    key: 'population-engagement',
    name: 'Population Engagement',
  },
  {
    key: 'population-biometrics',
    name: 'Population Biometrics',
  },
  // TODO: show when BE is fully ready
  // {
  //   key: 'program-completion',
  //   name: 'Program Completion',
  // },
  {
    key: 'action-spec',
    name: 'Action Spec',
  },
];

export const PATIENT_TAB_REPORTS: Array<ReportTabItem> = [
  {
    key: 'summary',
    name: 'Member Data Trend Report',
  },
  {
    key: 'data-summary',
    name: 'Member Data Summary Report',
  },
  // TODO: add them when we have designs
  // {
  //   key: 'engagement',
  //   name: 'Engagement',
  // },
  // {
  //   key: 'band',
  //   name: 'Band Status',
  // },
  // {
  //   key: 'risk-screening',
  //   name: 'Risk Screening',
  // },
];

export const PATIENT_TAB_PERIODS: any = {
  summary: ['daily', 'monthly', 'weekly'],
  'data-summary': ['monthly'],
};

export const OMIT_FILTER = ['data-summary'];

export const BILLING_REPORT_DESCRIPTION =
  'Description of the contents, purpose, and frequency of this report: Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.';

export const CLINICAL_REPORT_DESCRIPTION =
  "Welcome to the clinical summary. You'll find biometric and engagement data trends here. Please use it to review health patterns and adjust your plan accordingly.";
export const ADMIN_REPORT_DESCRIPTION =
  "Welcome to your administrative summary. You'll find compliance and logistical data trends for patients and clinicians here. You can use it to monitor department efficiency, manage staffing, or for reimbursement and billing purposes.";
