import { keyBy } from 'lodash';
import { ActionSpecReport } from 'models/actionSpecReport';
import { User } from '../models/user';
import { FactuaryCounter } from 'services/userReports/helpers/fetchFactuaryCounters';
import { ExternalIdColumn } from 'models/reports';

interface ReportsFromActionSpecProps {
  patients: User[];
  factuaryCounters: Record<string, FactuaryCounter>;
  clinicianId?: string;
}

interface CsvHeaderProps {
  externalIdColumns?: ExternalIdColumn[];
}

export const reportsFromActionSpec = ({
  patients,
  factuaryCounters,
  clinicianId,
}: ReportsFromActionSpecProps): ActionSpecReport[] => {
  const patientsByKey = keyBy(patients, 'id');

  if (clinicianId) {
    const counter = factuaryCounters[clinicianId];
    if (!counter?.actions?.length) {
      return [];
    }
    const actions = counter?.actions?.map((action) => {
      const patientProfile = patientsByKey[action.patientId];
      return {
        ...action,
        firstName: patientProfile?.personalInfo.firstName ?? null,
        lastName: patientProfile?.personalInfo.lastName ?? null,
        mrn: patientProfile?.externalIds?.MRN,
        employeeId: patientProfile?.externalIds?.EMPLOYEE_ID,
      };
    });

    return actions;
  }

  const actionSpec: ActionSpecReport[] = Object.values(factuaryCounters).flatMap(
    (factuaryCounter) => {
      const patientId = factuaryCounter.uid;
      const patientProfile = patientsByKey[patientId];

      const actions = factuaryCounter.actions.map((action) => ({
        ...action,
        patientId,
        firstName: patientProfile?.personalInfo.firstName ?? null,
        lastName: patientProfile?.personalInfo.lastName ?? null,
        mrn: patientProfile?.externalIds?.MRN,
        employeeId: patientProfile?.externalIds?.EMPLOYEE_ID,
      }));

      return actions;
    }
  );
  return actionSpec;
};

export const getCsvHeaders = ({ externalIdColumns }: CsvHeaderProps) => {
  const hasMRN = Object.keys(keyBy(externalIdColumns, 'key')).includes('externalMrn');
  const hasEmployeeId = Object.keys(keyBy(externalIdColumns, 'key')).includes('externalEmployeeId');
  return [
    { label: 'Action ID / reference #', key: 'action_id' },
    { label: 'Action Class', key: 'class' },
    { label: 'Action Category', key: 'category' },
    { label: 'Name of Action', key: 'title' },
    { label: 'Timestamp of Action, created', key: 'timeCreated' },
    { label: 'Impacted Patient FN', key: 'firstName' },
    { label: 'Impacted Patient LN', key: 'lastName' },
    { label: 'Impacted Patient ID', key: 'patientId' },
    ...(hasMRN ? [{ label: 'Impacted Patient eMRN', key: 'mrn' }] : []),
    ...(hasEmployeeId
      ? [
          {
            label: 'Impacted Patient Employee ID',
            key: 'employeeId',
          },
        ]
      : []),
    { label: 'Action Status / Disposition Status', key: 'status' },
    { label: 'Action Status / Disposition Timestamp', key: 'statusDate' },
    {
      label: 'Delta between created and status / disposition (seconds)',
      key: 'delta_time',
    },
  ];
};
