import { format } from 'date-fns';
import { orderBy, round } from 'lodash';
import { EngagementReportRawData, ExternalIdColumn, Period, PeriodItem } from 'models/reports';
import { formatSeconds } from './reports';

interface CsvHeaderProps {
  totalPeriodDays: number;
  externalIdColumns?: ExternalIdColumn[];
  period?: Period;
}

export const getCsvHeaders = ({ totalPeriodDays, externalIdColumns, period }: CsvHeaderProps) => {
  return [
    ...(period === 'monthly'
      ? [{ label: 'Reporting Month', key: 'month' }]
      : [{ label: 'Reporting Week', key: 'week' }]),
    { label: 'Reporting Year', key: 'year' },
    { label: 'First Name', key: 'givenName' },
    { label: 'Last Name', key: 'familyName' },
    { label: 'User ID', key: 'uid' },
    ...(externalIdColumns ?? []),
    { label: 'Account Status', key: 'memberStatus' },
    { label: 'Account Created', key: 'memberCreatedDate' },
    { label: 'Member Since', key: 'memberOnboardedDate' },
    { label: 'Account Age (d)', key: 'memberDaysSinceEnrolled' },
    { label: 'Device', key: 'device' },
    { label: 'Band Compliance', key: 'totalDaysBandSynced' },
    { label: `${totalPeriodDays} Day Avg %`, key: 'averageInteractions' },
    ...(period === 'monthly' ? [{ label: '16d Req', key: 'criteriaMet' }] : []),
    { label: 'Band Wearing Time', key: 'bandWearingTime' },
    { label: 'Band Removed Time', key: 'bandRemovedTime' },
    { label: 'Band Charging Time', key: 'bandChargeTime' },
    { label: 'Weight Readings', key: 'weightReadings' },
    { label: 'BP Readings', key: 'bpReadings' },
    { label: 'Glucometer Readings', key: 'glucometerReadings' },
  ];
};

interface ReportFromComplianceData {
  rawData: EngagementReportRawData[];
  period: PeriodItem;
}

export const reportFromComplianceData = ({
  rawData,
  period,
}: ReportFromComplianceData): EngagementReportRawData[] => {
  return orderBy(
    rawData.map((item: any) => {
      return {
        ...item,
        ...(period.period === 'monthly'
          ? { month: period.periodNumber }
          : { week: period.periodNumber }),
        year: period.year,
        givenName: item.first_name ?? '',
        familyName: item.last_name ?? '',
        uid: item.user_id ?? '',
        mrn: item.mrn ?? null,
        externalMrn: item.mrn ?? null,
        externalEmployeeId: item.employee_id || null,
        memberStatus: item.member_status ?? null,
        memberCreatedAt: item.account_created || null,
        memberCreatedDate: item.account_created
          ? format(new Date(item.account_created * 1000 || 0), 'MM/dd/yyyy')
          : null,
        memberOnboardedDate: item.member_since
          ? format(new Date(item.member_since * 1000 || 0), 'MM/dd/yyyy')
          : null,
        memberOnboardedTimestamp: item.member_since ?? null,
        memberDaysSinceEnrolled: item?.member_seconds_enrolled
          ? Math.ceil(item.member_seconds_enrolled / 86400)
          : null,
        device: item.device ?? 'Band01',
        totalDaysBandSynced: Number(item.band_compliance) ? item.band_compliance : null,
        averageInteractions: item.interval_day_avg ? round(item.interval_day_avg * 100, 2) : null,
        criteriaMet:
          period?.period === 'monthly'
            ? item?.band_compliance && item.band_compliance >= 16
              ? 'Yes'
              : 'No'
            : null,
        bandWearingTime: item.band_wearing_time ? formatSeconds(item.band_wearing_time) : null,
        bandRemovedTime: item.band_removed_time ? formatSeconds(item.band_removed_time) : null,
        bandChargeTime: item.band_charging_time ? formatSeconds(item.band_charging_time) : null,
        bandWearingSeconds: item.band_wearing_time ?? null,
        bandRemovedSeconds: item.band_removed_time ?? null,
        bandChargeSeconds: item.band_charging_time ?? null,
        weightReadings: Number(item.weight_readings) ? item.weight_readings : null,
        bpReadings: Number(item.bp_readings) ? item.bp_readings : null,
        glucometerReadings: Number(item.glucometer_readings) ? item.glucometer_readings : null,
      };
    }),
    [(report) => report.givenName?.toLowerCase().replace(/[^\w]/gi, '')]
  );
};
